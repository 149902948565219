<template>
  <label class="v-radio-checkbox" :class="{ radio: type === 'radio' }">
    <input
      :type="type"
      :name="name"
      :checked="checked"
      @change="$emit('change', !checked)"
    />
    <div v-if="type === 'radio'" class="v-radio-checkbox__circle"></div>

    <div v-if="type === 'checkbox'" class="v-radio-checkbox__check">
      <IconCheck />
    </div>

    <div class="v-radio-checkbox__text">
      <slot />
    </div>
  </label>
</template>

<script>
import IconCheck from '@/assets/icons/check2.svg';

export default {
  name: 'VRadioCheckbox',
  components: {
    IconCheck,
  },

  props: {
    /**
     * @param {'checkbox'|'radio'} type
     */
    type: {
      type: String,
      default: 'checkbox',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.dark-theme {
  .v-radio-checkbox {
    &:hover {
      .v-radio-checkbox__circle {
        border-color: darken($darkThemeBgTwo, 10%)
      }
    }

    input {
      &:checked ~ {
        .v-radio-checkbox__check {
          border-color: $darkThemeBlue;
          background: $darkThemeBlue;
        }
      }
    }

    &__circle {
      border: 1px solid $darkThemeBgThree;
      background: $darkThemeBgTwo;

      &:before {
        background: $darkThemeBlue;
      }
    }

    &__check {
      background: $darkThemeBgTwo;
      border: 1px solid $darkThemeBgThree;

      svg {
        path {
          fill: #000;
        }
      }
    }

    &__text {
      svg {
        path {
          &:nth-child(1) {
            fill: $darkThemeLightBlue
          }

          &:nth-child(2) {
            fill: $darkThemeBlue
          }
        }
      }
    }
  }
}

.v-radio-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  $root: &;

  &:hover {
    #{$root} {
      &__circle {
        border-color: darken(#d4d4d4, 10%)
      }
    }
  }

  &.radio {
    #{$root} {
      &__circle {
        border-radius: 50%;
      }
    }
  }

  input {
    display: none;

    &:checked ~ {
      #{$root} {
        &__circle {
          &:before {
            opacity: 1;
            transform: none;
          }
        }

        &__check {
          border-color: $blue;
          background: $blue;

          svg {
            opacity: 1;
            transform: none;
          }
        }
      }
    }
  }

  &__circle {
    border: 1px solid #d4d4d4;
    height: 23px;
    width: 23px;
    flex: none;
    margin-right: 10px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: '';
      display: block;
      background: $blue;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      opacity: 0;
      transform: scale(.5);
      transition: .3s cubic-bezier(0.06, 0.96, 0.37, 1.5)
    }
  }

  &__check {
    border: 1px solid #d4d4d4;
    height: 23px;
    width: 23px;
    flex: none;
    margin-right: 10px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      display: block;
      width: 14px;
      height: 14px;
      opacity: 0;
      transform: scale(.5);
      transition: .3s cubic-bezier(0.06, 0.96, 0.37, 1.5);

      path {
        fill: #fff;
      }
    }
  }

  &__text {
    font-size: 14px;
    display: flex;
    align-items: center;

    svg {
      display: block;
      width: 16px;
      height: 16px;
      margin-left: 10px;

      path {
        &:nth-child(1) {
          fill: $lightBlue
        }

        &:nth-child(2) {
          fill: $blue
        }
      }
    }
  }
}
</style>
